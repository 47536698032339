import React from "react"
import Layout from "../../components/layout"
import { Container, Wrapper } from "../../theme"
import {
  ServicesRow,
  Process,
  ServiceExamples,
  IconRow,
} from "../../components"

const Branding = () => {
  return (
    <Layout title="Branding and Content Creation" type="branding">
      <Wrapper white>
        <Container>
          <IconRow type="branding" />
        </Container>
        <ServicesRow
          serviceTitle="Attracting Customers Through Professional Branding"
          subtitle="Creating a brand that works for your business"
          category="Our Branding and Content Creation Services"
          service="branding"
          paragraph1="Branding is the key that unlocks everything in your business. With great marketing, your company can enjoy the benefits of having leads come to you. That's our goal here at CheshTech is to help bring new business to you without the pains of having to do any cold outreach. Nobody likes cold calling or getting cold called!"
          paragraph2="We work specifically with you to learn about the goals of your business and how your marketing supports those goals. We will create a customized strategy for your business based on those goals in order to get maximized results."
          paragraph3="We are proud to offer a variety of funnel driven services to drive traffic to your website, or complete your specific call to action. We focus our digital marketing services on social media management, content creation, branding, email marketing, On-page and technical SEO, and content marketing strategies including blogging and vlogging."
        />
        <Process type="branding" />
        <ServiceExamples service="branding" title="Some of our branding work" />
      </Wrapper>
    </Layout>
  )
}

export default Branding
